import AdvisorUserToken from '@apps/truevail/admin/tokens/advisor_user'
import UserStatusToken from '@apps/truevail/admin/tokens/user_status'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Users)',
  collection: {
    endpoint: '/api/admin/truevail/agency/users',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: AdvisorUserToken },
      { label: 't(Activated)', key: 'account.activation_at', primary: true, collapsing: true, format: UserStatusToken }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      icon: 'user',
      title: 't(No Users)',
      text: 't(You have not yet created any users for any advisors)'
    },
    recordTasks: (record) => [
      {
        label: 't(Unblock User)',
        show: record.is_blocked,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/truevail/agency/users/${record.id}/unblock`,
          onFailure: (reult) => context.flash.set('error', 't(Unable to unblock user)'),
          onSuccess: () => context.flash.set('success', 't(The user has been unblocked)')
        }
      },
      {
        label: 't(Send Password Reset)',
        show: record.account.activated_at !== null,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/truevail/agency/users/${record.id}/reset`,
          onFailure: () => context.flash.set('error', 't(Unable to send reset email)'),
          onSuccess: () => context.flash.set('success', 't(Email queued for delivery)')
        }
      },
      {
        label: 't(Send Activation Email)',
        show: record.account.activated_at === null,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/truevail/agency/users/${record.id}/activate`,
          onFailure: (result) => context.flash.set('error', 't(Unable to send activation email)'),
          onSuccess: (result) => context.flash.set('success', 't(Email queued for delivery)')
        }
      }
    ],
    entity: 'user'
  }
})

export default Panel(null, mapPropsToPanel)
